<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
         style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Party Ledger Details</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="card mt-1">
                    <div class="d-flex justify-content-between px-2">
                        <div>
                            <h3>Party: {{party.name ?? ''}}</h3>
                            {{date_range}}
                        </div>
                        <div>
                            <table class="head">
                                <tr>
                                    <td>Opening Balance :</td>
                                    <td>
                                        <strong>{{
                                                ledgerData.opening_balance
                                                    ? commaFormat(ledgerData.opening_balance)
                                                    : 0.0
                                            }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Debit :</td>
                                    <td>
                                        <strong>{{
                                                ledgerData.total_debit
                                                    ? commaFormat(ledgerData.total_debit)
                                                    : 0.0
                                            }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Credit :</td>
                                    <td>
                                        <strong>{{
                                                ledgerData.total_credit
                                                    ? commaFormat(ledgerData.total_credit)
                                                    : 0.0
                                            }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Closing Balance :</td>
                                    <td>
                                        <strong>{{
                                                ledgerData.closing_balance
                                                    ? commaFormat(ledgerData.closing_balance)
                                                    : 0.0
                                            }}</strong>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <LedgerTable
                        :openingBalance="ledgerData.opening_balance"
                        :closingBalance="ledgerData.closing_balance"
                        :totalDebitBalance="ledgerData.total_debit"
                        :totalCreditBalance="ledgerData.total_credit"
                        :currentPage="ledgerData?.ledgers?.current_page"
                        :lastPage="ledgerData?.ledgers?.last_page"
                        :ledgers="ledgers"
                        :modal="true"
                    />
                </div>
                <global-loader v-if="reportLoading" />
            </div>
        </div>
    </div>
</template>

<script setup>
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {computed, inject, ref} from "vue";
import LedgerTable from "@/components/molecule/company/ledger/LedgerTable.vue";
import Pagination from "@/components/atom/Pagination";
import figureFormatter from "@/services/utils/figureFormatter";
import {useStore} from "vuex";
import handleReport from "@/services/modules/accountingReport";
import {useRoute} from "vue-router";

const { commaFormat } = figureFormatter();
const { getPartyLedger } = handleReport();
const $store = useStore();
const $route = useRoute()
const $props = defineProps({
    contact_profile_id: {
        type: Number,
        default: undefined
    },
    partyDetails: {
        type: Object,
        default: undefined
    },
    party: {
        type: Object,
        required: true
    },
    date_range: {
        type: String,
        default: ''
    }
});
const showError = inject("showError");
const showSuccess = inject("showSuccess");

const accountHeads = ref([]);
const headId = ref(null);
const homeData = ref({
    txn_type : {},
    voucher_type : {},
});
const contactProfiles = ref([]);
const businesses = ref([]);
const business_id = ref(null);
const voucher_type = ref(null);
const txn_type = ref(null);
const reportLoading = ref(false);
const isOpen = ref(false);
//computed

const contact_profile_id = computed(() => $props.contact_profile_id);
const ledgerData = computed(() => $props.partyDetails)
const ledgers = computed(() => $props.partyDetails.ledgers)

//methods

const getQuery = () => {
    let query = "?company_id=" + companyId;
    query += "&contact_profile_id=" + contact_profile_id.value;
    if (page) query += "&page=" + page;
    query += "&offset=" + offset;
    return query;
}

const toggleModal = () => {
    isOpen.value = !isOpen.value
}

defineExpose({
    toggleModal,
})

</script>

<style scoped>

</style>